var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-navigation-drawer',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"sidebar-nl",class:{
    'navigation-list-post': _vm.$vuetify.breakpoint.smAndDown && _vm.drawerSidebar,
  },style:({
    position: _vm.$vuetify.breakpoint.smAndDown ? 'fixed' : 'relative',
  }),attrs:{"value":_vm.drawerSidebar,"mini-variant":_vm.mini,"permanent":_vm.$vuetify.breakpoint.mdAndUp,"absolute":_vm.$vuetify.breakpoint.smAndDown,"temporary":_vm.$vuetify.breakpoint.smAndDown,"width":_vm.drawerSidebar ? 'auto' : '200px',"data-cy":"navigationDrawerSidebarFuncionario"}},[_c('v-card',{staticClass:"py-2 body-2 card-list-post-sidebar",staticStyle:{"border-radius":"0px !important"},attrs:{"width":"auto"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-list',{staticClass:"menu-sidebar"},[_c('v-list-item-group',[_c('div',{staticClass:"pa-5 d-flex flex-column align-start"},[_c('Logo',{attrs:{"height-image":"50px","image-url":_vm.$store.getters['brand/logo'],"position":"left","to":"/"}})],1)])],1):_vm._e(),_vm._v(" "),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-divider'):_vm._e(),_vm._v(" "),_c('v-list',{staticClass:"pa-0"},[_c('v-list-item',{staticClass:"py-1 px-0",class:{ 'justify-center': _vm.mini, 'mx-3': !_vm.mini },attrs:{"link":"","to":"/admin/profile","data-cy":"itemAvatarUser"},on:{"click":function($event){return _vm.closeSubmenus()}}},[_c('v-list-item-icon',{staticClass:"text-h6 justify-start mx-auto",class:{ 'mr-3': !_vm.mini, 'pl-1': _vm.mini, 'pl-1': !_vm.mini }},[_c('v-avatar',{attrs:{"color":"primary","size":"35"}},[(_vm.user.picture)?_c('img',{attrs:{"src":_vm.user.picture}}):(_vm.user.first_name)?_c('span',{staticClass:"white--text subtitle-1"},[_vm._v("\n              "+_vm._s(_vm.user.first_name.charAt(0) + _vm.user.last_name.charAt(0))+"\n            ")]):_vm._e()])],1),_vm._v(" "),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_c('div',[(
                  _vm.$store.getters['authconfig/isDID'] &&
                  _vm.$store.getters['authconfig/autenticar']
                )?_c('label',{staticClass:"d-block"},[_vm._v("\n                "+_vm._s(_vm.userName)+"\n                "),_c('br'),_vm._v("\n                "+_vm._s(_vm.user.levelCidi ? `Nivel ${_vm.user.levelCidi}` : 'Nivel X')+"\n              ")]):_c('label',{staticClass:"d-block"},[_vm._v("\n                "+_vm._s(_vm.userName)+"\n              ")])])]),_vm._v(" "),(_vm.auxRole !== 'visualizer')?_c('v-list-item-subtitle',[_vm._v("\n            "+_vm._s(_vm.typeProfile)+"\n          ")]):_c('v-list-item-subtitle',[_vm._v("\n            "+_vm._s(_vm.$t('sidebar.visualizerTitle'))+"\n          ")])],1)],1)],1),_vm._v(" "),(_vm.typeProfile === 'Funcionario')?[_c('div',{staticClass:"scroll pt-1 px-3"},[_c('v-list',[(
              ['superadmin', 'admin', 'operator', 'editor'].includes(_vm.role) ||
              (_vm.auxRole === 'visualizer' &&
                _vm.involvedModules.includes('procedures'))
            )?_c('v-list-group',{class:_vm.mini &&
              _vm.$route.fullPath.includes('admin/modules/ventanillaDigital')
                ? 'active-group'
                : '',on:{"click":function($event){_vm.changeLastActive('closeVentanillaDigital')
              _vm.$emit('openSidebar')}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mx-0",class:{ 'justify-center': _vm.mini }},[_c('ModuleIcon',{attrs:{"fill":'#717680',"width":'15px',"height":'15px'}})],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"mb-0"},[_vm._v("\n                "+_vm._s(_vm.$t('sidebar.ventanillaDigital'))+"\n              ")])]},proxy:true}],null,false,4123941313),model:{value:(_vm.closeVentanillaDigital),callback:function ($$v) {_vm.closeVentanillaDigital=$$v},expression:"closeVentanillaDigital"}},[_vm._v(" "),_vm._l((_vm.ventanillaDigitalPages),function(item,i){return [(_vm.checkIfRoleIsAllowed(item))?_c('v-list-item',{key:i,staticClass:"ml-2",attrs:{"to":item.link,"active-class":_vm.$route.fullPath === item.link ? '' : 'active-item'}},[_c('v-list-item-icon',{staticClass:"mx-0 mt-5",class:{ 'justify-center': _vm.mini }},[_c('v-icon',{attrs:{"x-small":"","color":"black"}},[_vm._v(" mdi-circle-medium ")])],1),_vm._v(" "),_c('p',{staticClass:"mb-0 black--text"},[_vm._v(_vm._s(item.title))])],1):_vm._e()]})],2):_vm._e(),_vm._v(" "),(
              (['admin', 'operator', 'editor'].includes(_vm.inspectorRole) &&
                _vm.auxRole === 'officer') ||
              _vm.role === 'superadmin' ||
              (_vm.auxRole === 'visualizer' &&
                _vm.involvedModules.includes('inspectors'))
            )?_c('v-list-group',{class:_vm.mini && _vm.$route.fullPath.includes('admin/inspectores')
                ? 'active-group'
                : '',on:{"click":function($event){_vm.changeLastActive('closeInspectors')
              _vm.$emit('openSidebar')}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mx-0",class:{ 'justify-center': _vm.mini }},[_c('InspectorIcon',{attrs:{"fill":'#717680',"width":'22px',"height":'22px'}})],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"mb-0"},[_vm._v("\n                "+_vm._s(_vm.$t('sidebar.inspectors'))+"\n              ")])]},proxy:true}],null,false,1874216988),model:{value:(_vm.closeInspectors),callback:function ($$v) {_vm.closeInspectors=$$v},expression:"closeInspectors"}},[_vm._v(" "),_vm._l((_vm.inspectorPages),function(item,i){return [(_vm.checkIfRoleIsAllowed(item))?_c('v-list-item',{key:i,staticClass:"ml-2",attrs:{"to":item.link,"active-class":_vm.$route.fullPath === item.link ? '' : 'active-item'}},[_c('v-list-item-icon',{staticClass:"mx-0 mt-5",class:{ 'justify-center': _vm.mini }},[_c('v-icon',{attrs:{"x-small":"","color":"black"}},[_vm._v(" mdi-circle-medium ")])],1),_vm._v(" "),_c('p',{staticClass:"mb-0 black--text"},[_vm._v(_vm._s(item.title))])],1):_vm._e()]})],2):_vm._e(),_vm._v(" "),(
              (['admin', 'operator', 'editor'].includes(_vm.prevDeliveryRole) &&
                _vm.auxRole === 'officer') ||
              _vm.role === 'superadmin' ||
              (_vm.auxRole === 'visualizer' &&
                _vm.involvedModules.includes('preventiveDelivery'))
            )?_c('v-list-group',{class:_vm.mini && _vm.$route.fullPath.includes('admin/preventiveDelivery')
                ? 'active-group'
                : '',on:{"click":function($event){_vm.changeLastActive('closeNotification')
              _vm.$emit('openSidebar')}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mx-0",class:{ 'justify-center': _vm.mini }},[_c('NotificatorIcon',{attrs:{"fill":'#717680',"width":'20px',"height":'20px'}})],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"mb-0"},[_vm._v("\n                "+_vm._s(_vm.$t('sidebar.notificators'))+"\n              ")])]},proxy:true}],null,false,1313493532),model:{value:(_vm.closeNotification),callback:function ($$v) {_vm.closeNotification=$$v},expression:"closeNotification"}},[_vm._v(" "),_vm._l((_vm.notificationPages),function(item,i){return [(_vm.checkIfRoleIsAllowed(item))?_c('v-list-item',{key:i,staticClass:"ml-2",attrs:{"to":item.link,"active-class":_vm.$route.fullPath === item.link ? '' : 'active-item'}},[_c('v-list-item-icon',{staticClass:"mx-0 mt-5",class:{ 'justify-center': _vm.mini }},[_c('v-icon',{attrs:{"x-small":"","color":"black"}},[_vm._v(" mdi-circle-medium ")])],1),_vm._v(" "),_c('p',{staticClass:"mb-0 black--text"},[_vm._v(_vm._s(item.title))])],1):_vm._e()]})],2):_vm._e(),_vm._v(" "),(
              (['admin', 'tesorero'].includes(_vm.paymentsRole) &&
                _vm.auxRole === 'officer') ||
              _vm.role === 'superadmin' ||
              (_vm.auxRole === 'visualizer' &&
                _vm.involvedModules.includes('payments'))
            )?_c('v-list-group',{class:_vm.mini && _vm.$route.fullPath.includes('admin/payments')
                ? 'active-group'
                : '',on:{"click":function($event){_vm.changeLastActive('closePayment')
              _vm.$emit('openSidebar')}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mx-0",class:{ 'justify-center': _vm.mini }},[_c('CardPayIcon',{attrs:{"fill":'#717680',"width":'20px',"height":'20px'}})],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"mb-0"},[_vm._v("\n                "+_vm._s(_vm.$t('sidebar.payments'))+"\n              ")])]},proxy:true}],null,false,2363468602),model:{value:(_vm.closePayment),callback:function ($$v) {_vm.closePayment=$$v},expression:"closePayment"}},[_vm._v(" "),_vm._l((_vm.paymentPages),function(item,i){return [(_vm.checkIfRoleIsAllowed(item))?_c('v-list-item',{key:i,staticClass:"ml-2",attrs:{"to":item.link,"active-class":_vm.$route.fullPath === item.link ? '' : 'active-item'}},[_c('v-list-item-icon',{staticClass:"mx-0 mt-5",class:{ 'justify-center': _vm.mini }},[_c('v-icon',{attrs:{"x-small":"","color":"black"}},[_vm._v(" mdi-circle-medium ")])],1),_vm._v(" "),_c('p',{staticClass:"mb-0 black--text"},[_vm._v(_vm._s(item.title))])],1):_vm._e()]})],2):_vm._e(),_vm._v(" "),(
              (['admin', 'operator', 'editor'].includes(_vm.apptsRole) &&
                _vm.auxRole === 'officer') ||
              _vm.role === 'superadmin' ||
              (_vm.auxRole === 'visualizer' &&
                _vm.involvedModules.includes('appointments'))
            )?_c('v-list-group',{class:_vm.mini && _vm.$route.fullPath.includes('admin/citas')
                ? 'active-group'
                : '',on:{"click":function($event){_vm.changeLastActive('closeAppointments')
              _vm.$emit('openSidebar')}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mx-0",class:{ 'justify-center': _vm.mini }},[_c('CalendarioIcon',{attrs:{"fill":'#717680',"width":'18px',"height":'18px'}})],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"mb-0"},[_vm._v("\n                "+_vm._s(_vm.$t('sidebar.appointments'))+"\n              ")])]},proxy:true}],null,false,2275028199),model:{value:(_vm.closeAppointments),callback:function ($$v) {_vm.closeAppointments=$$v},expression:"closeAppointments"}},[_vm._v(" "),_vm._l((_vm.apptPages),function(item,i){return [(_vm.checkIfRoleIsAllowed(item))?_c('v-list-item',{key:i,staticClass:"ml-2",attrs:{"to":item.link,"active-class":_vm.$route.fullPath === item.link ? '' : 'active-item'}},[_c('v-list-item-icon',{staticClass:"mx-0 mt-5",class:{ 'justify-center': _vm.mini }},[_c('v-icon',{attrs:{"x-small":"","color":"black"}},[_vm._v(" mdi-circle-medium ")])],1),_vm._v(" "),_c('p',{staticClass:"mb-0 black--text"},[_vm._v(_vm._s(item.title))])],1):_vm._e()]})],2):_vm._e(),_vm._v(" "),(
              ['superadmin', 'admin', 'editor'].includes(_vm.role) ||
              (_vm.auxRole === 'visualizer' &&
                _vm.involvedModules.includes('dashboard'))
            )?_c('v-divider'):_vm._e(),_vm._v(" "),(_vm.role === 'superadmin')?_c('v-list-group',{class:_vm.mini && _vm.$route.fullPath.includes('admin/users')
                ? 'active-group'
                : '',on:{"click":function($event){_vm.changeLastActive('closeUsers')
              _vm.$emit('openSidebar')}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mx-0",class:{ 'justify-center': _vm.mini }},[_c('UserIcon',{attrs:{"fill":'#717680',"width":'15px',"height":'15px'}})],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"mb-0"},[_vm._v("\n                "+_vm._s(_vm.$t('sidebar.users'))+"\n              ")])]},proxy:true}],null,false,3832056188),model:{value:(_vm.closeUsers),callback:function ($$v) {_vm.closeUsers=$$v},expression:"closeUsers"}},[_vm._v(" "),_vm._l((_vm.userPages),function(item,i){return [(
                  item.type !== 'usrInstitutional' ||
                  (item.type === 'usrInstitutional' &&
                    _vm.activePrivateProcedures)
                )?_c('v-list-item',{key:i,staticClass:"ml-2",attrs:{"to":item.link,"active-class":_vm.$route.fullPath === item.link ? '' : 'active-item'}},[_c('v-list-item-icon',{staticClass:"mx-0 mt-5",class:{ 'justify-center': _vm.mini }},[_c('v-icon',{attrs:{"x-small":"","color":"black"}},[_vm._v(" mdi-circle-medium ")])],1),_vm._v(" "),_c('p',{staticClass:"mb-0 black--text"},[_vm._v(_vm._s(item.title))])],1):_vm._e()]})],2):_vm._e(),_vm._v(" "),(['superadmin', 'admin', 'editor'].includes(_vm.role))?_c('v-list-item',{attrs:{"to":"/admin/catalogs"},on:{"click":function($event){return _vm.closeSubmenus()}}},[_c('v-list-item-icon',{staticClass:"mx-0",class:{ 'justify-center': _vm.mini }},[_c('CatalogosIcon',{attrs:{"fill":'#717680',"width":'16px',"height":'16px'}})],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t('sidebar.catalogs'))+"\n            ")])],1):_vm._e(),_vm._v(" "),(['superadmin', 'admin', 'editor'].includes(_vm.role))?_c('v-list-item',{attrs:{"to":"/admin/filters"},on:{"click":function($event){return _vm.closeSubmenus()}}},[_c('v-list-item-icon',{staticClass:"mx-0",class:{ 'justify-center': _vm.mini }},[_c('FilterIcon',{attrs:{"fill":'#717680',"width":'21px',"height":'21px'}})],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t('sidebar.filtros'))+"\n            ")])],1):_vm._e(),_vm._v(" "),(_vm.role === 'superadmin')?_c('v-list-item',{attrs:{"to":"/admin/settings"},on:{"click":function($event){return _vm.closeSubmenus()}}},[_c('v-list-item-icon',{staticClass:"mx-0",class:{ 'justify-center': _vm.mini }},[_c('ConfiguracionIcon',{attrs:{"fill":'#717680',"width":'16px',"height":'16px'}})],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t('sidebar.settings'))+"\n            ")])],1):_vm._e(),_vm._v(" "),(
              _vm.role === 'superadmin' ||
              (_vm.auxRole === 'visualizer' &&
                _vm.involvedModules.includes('dashboard'))
            )?_c('v-list-item',{attrs:{"to":"/admin/dashboard"},on:{"click":function($event){return _vm.closeSubmenus()}}},[_c('v-list-item-icon',{staticClass:"mx-0",class:{ 'justify-center': _vm.mini }},[_c('EstadisticasIcon',{attrs:{"fill":'#717680',"width":'16px',"height":'16px'}})],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t('sidebar.statistics'))+"\n            ")])],1):_vm._e(),_vm._v(" "),_c('v-divider'),_vm._v(" "),(['superadmin', 'admin'].includes(_vm.role))?_c('v-list-item',{attrs:{"href":"https://plus.os.city/","target":"_blank"}},[_c('v-list-item-icon',{staticClass:"mx-0",class:{ 'justify-center': _vm.mini }},[_c('OSCityIcon',{attrs:{"fill":'#717680',"width":'16px',"height":'16px'}})],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"mb-0"},[_vm._v(" OS City + ")])],1):_vm._e(),_vm._v(" "),_c('v-list-item',{on:{"click":_vm.logoutIf}},[_c('v-list-item-icon',{staticClass:"mx-0",class:{ 'justify-center': _vm.mini }},[_c('v-icon',{attrs:{"color":"red","size":"20px"}},[_vm._v(" mdi-logout-variant ")])],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t('cerrar-sesion'))+"\n            ")])],1)],1)],1)]:_vm._e(),_vm._v(" "),_c('v-snackbar',{attrs:{"top":true,"right":true,"timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color,"multi-line":_vm.snackbar.mode === 'multi-line',"vertical":_vm.snackbar.mode === 'vertical'},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v("\n      "+_vm._s(_vm.snackbar.text)+"\n      "),_c('v-btn',{staticClass:"float-right",attrs:{"icon":"","justify":"center","align":"center"},nativeOn:{"click":function($event){_vm.snackbar.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }