import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6a275ef7 = () => interopDefault(import('../pages/autenticar-error.vue' /* webpackChunkName: "pages/autenticar-error" */))
const _6b643131 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _7890c21e = () => interopDefault(import('../pages/auth/index/ChangePasswordFuncionarios.vue' /* webpackChunkName: "pages/auth/index/ChangePasswordFuncionarios" */))
const _8eca0ece = () => interopDefault(import('../pages/auth/index/ChangePasswordInstitucionales.vue' /* webpackChunkName: "pages/auth/index/ChangePasswordInstitucionales" */))
const _6fc3a381 = () => interopDefault(import('../pages/auth/index/LinkWallet.vue' /* webpackChunkName: "pages/auth/index/LinkWallet" */))
const _5299dc2a = () => interopDefault(import('../pages/auth/index/Login.vue' /* webpackChunkName: "pages/auth/index/Login" */))
const _bd87e5a6 = () => interopDefault(import('../pages/auth/index/login-funcionarios/index.vue' /* webpackChunkName: "pages/auth/index/login-funcionarios/index" */))
const _6c0796d6 = () => interopDefault(import('../pages/auth/index/login-institucional/index.vue' /* webpackChunkName: "pages/auth/index/login-institucional/index" */))
const _12149344 = () => interopDefault(import('../pages/auth/index/LoginWallet.vue' /* webpackChunkName: "pages/auth/index/LoginWallet" */))
const _4cc033f8 = () => interopDefault(import('../pages/auth/index/Logout.vue' /* webpackChunkName: "pages/auth/index/Logout" */))
const _e23989de = () => interopDefault(import('../pages/auth/index/Register.vue' /* webpackChunkName: "pages/auth/index/Register" */))
const _77b0dd0c = () => interopDefault(import('../pages/auth/index/ResetPassword.vue' /* webpackChunkName: "pages/auth/index/ResetPassword" */))
const _7d22cc1d = () => interopDefault(import('../pages/digital-citizen.vue' /* webpackChunkName: "pages/digital-citizen" */))
const _2c57bb4f = () => interopDefault(import('../pages/digital-citizen-levels.vue' /* webpackChunkName: "pages/digital-citizen-levels" */))
const _60067b81 = () => interopDefault(import('../themes/Andromeda/pages/digital-services/index.vue' /* webpackChunkName: "pages/digital-services/index" */))
const _6fbe968a = () => interopDefault(import('../pages/email-action-handler.vue' /* webpackChunkName: "pages/email-action-handler" */))
const _12764f66 = () => interopDefault(import('../pages/expediente.vue' /* webpackChunkName: "pages/expediente" */))
const _26c55b2c = () => interopDefault(import('../pages/expediente/apoderados.vue' /* webpackChunkName: "pages/expediente/apoderados" */))
const _29041de9 = () => interopDefault(import('../pages/expediente/appointments/index.vue' /* webpackChunkName: "pages/expediente/appointments/index" */))
const _62f70ace = () => interopDefault(import('../pages/expediente/companies.vue' /* webpackChunkName: "pages/expediente/companies" */))
const _6ac47fac = () => interopDefault(import('../pages/expediente/identidad.vue' /* webpackChunkName: "pages/expediente/identidad" */))
const _67f23b80 = () => interopDefault(import('../pages/expediente/inspections/index.vue' /* webpackChunkName: "pages/expediente/inspections/index" */))
const _7cecae22 = () => interopDefault(import('../pages/expediente/payments/index.vue' /* webpackChunkName: "pages/expediente/payments/index" */))
const _31cb2306 = () => interopDefault(import('../pages/expediente/portadocumentos.vue' /* webpackChunkName: "pages/expediente/portadocumentos" */))
const _feda08d8 = () => interopDefault(import('../pages/expediente/appointments/_appointmentId.vue' /* webpackChunkName: "pages/expediente/appointments/_appointmentId" */))
const _5690d23c = () => interopDefault(import('../pages/expediente/inspections/_inspectionId.vue' /* webpackChunkName: "pages/expediente/inspections/_inspectionId" */))
const _4651c3b1 = () => interopDefault(import('../pages/expediente/module/_module_id/_submodule_id/index.vue' /* webpackChunkName: "pages/expediente/module/_module_id/_submodule_id/index" */))
const _51e3d4f6 = () => interopDefault(import('../pages/expediente/module/_module_id/_submodule_id/_details_id.vue' /* webpackChunkName: "pages/expediente/module/_module_id/_submodule_id/_details_id" */))
const _6b59a400 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _1b80743a = () => interopDefault(import('../pages/forbidden.vue' /* webpackChunkName: "pages/forbidden" */))
const _c59b9404 = () => interopDefault(import('../pages/forward-email.vue' /* webpackChunkName: "pages/forward-email" */))
const _2a138174 = () => interopDefault(import('../pages/mercadopago.vue' /* webpackChunkName: "pages/mercadopago" */))
const _38463aec = () => interopDefault(import('../pages/motordepagos.vue' /* webpackChunkName: "pages/motordepagos" */))
const _5f559c91 = () => interopDefault(import('../pages/notice-of-privacy.vue' /* webpackChunkName: "pages/notice-of-privacy" */))
const _fc2c8b78 = () => interopDefault(import('../pages/persona-moral.vue' /* webpackChunkName: "pages/persona-moral" */))
const _4ca8cdfd = () => interopDefault(import('../pages/predial.vue' /* webpackChunkName: "pages/predial" */))
const _68650242 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _ec5654f8 = () => interopDefault(import('../pages/server-error.vue' /* webpackChunkName: "pages/server-error" */))
const _71cb36fa = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _0c02766a = () => interopDefault(import('../pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _fefc4974 = () => interopDefault(import('../pages/validacion-pago.vue' /* webpackChunkName: "pages/validacion-pago" */))
const _29824366 = () => interopDefault(import('../pages/verificador/index.vue' /* webpackChunkName: "pages/verificador/index" */))
const _adc04386 = () => interopDefault(import('../pages/what-is.vue' /* webpackChunkName: "pages/what-is" */))
const _21ee447a = () => interopDefault(import('../pages/admin/catalogs/index.vue' /* webpackChunkName: "pages/admin/catalogs/index" */))
const _fd67f8a6 = () => interopDefault(import('../pages/admin/citas/index.vue' /* webpackChunkName: "pages/admin/citas/index" */))
const _68f58a7e = () => interopDefault(import('../pages/admin/dashboard/index.vue' /* webpackChunkName: "pages/admin/dashboard/index" */))
const _8f860830 = () => interopDefault(import('../pages/admin/filters/index.vue' /* webpackChunkName: "pages/admin/filters/index" */))
const _9f536f5c = () => interopDefault(import('../pages/admin/inspectores/index.vue' /* webpackChunkName: "pages/admin/inspectores/index" */))
const _6adf8074 = () => interopDefault(import('../pages/admin/modules/index.vue' /* webpackChunkName: "pages/admin/modules/index" */))
const _5df4f494 = () => interopDefault(import('../pages/admin/payments/index.vue' /* webpackChunkName: "pages/admin/payments/index" */))
const _6e2fd45d = () => interopDefault(import('../pages/admin/preventiveDelivery/index.vue' /* webpackChunkName: "pages/admin/preventiveDelivery/index" */))
const _305bbfd4 = () => interopDefault(import('../pages/admin/profile/index.vue' /* webpackChunkName: "pages/admin/profile/index" */))
const _dc0a11a8 = () => interopDefault(import('../pages/admin/settings/index.vue' /* webpackChunkName: "pages/admin/settings/index" */))
const _a60f3dd8 = () => interopDefault(import('../pages/auth/CompleteData.vue' /* webpackChunkName: "pages/auth/CompleteData" */))
const _0b3e72cc = () => interopDefault(import('../pages/citizen/certificates/index.vue' /* webpackChunkName: "pages/citizen/certificates/index" */))
const _700c1a63 = () => interopDefault(import('../pages/tools/ide/index.vue' /* webpackChunkName: "pages/tools/ide/index" */))
const _175f49d1 = () => interopDefault(import('../pages/admin/catalogs/new.vue' /* webpackChunkName: "pages/admin/catalogs/new" */))
const _474d1094 = () => interopDefault(import('../pages/admin/citas/configuration/index.vue' /* webpackChunkName: "pages/admin/citas/configuration/index" */))
const _57b88c28 = () => interopDefault(import('../pages/admin/forms/builder/index.vue' /* webpackChunkName: "pages/admin/forms/builder/index" */))
const _e1085f0e = () => interopDefault(import('../pages/admin/inspectores/configuration/index.vue' /* webpackChunkName: "pages/admin/inspectores/configuration/index" */))
const _d6b1b5ca = () => interopDefault(import('../pages/admin/modules/configuration/index.vue' /* webpackChunkName: "pages/admin/modules/configuration/index" */))
const _472a4c56 = () => interopDefault(import('../pages/admin/modules/indexBackup.vue' /* webpackChunkName: "pages/admin/modules/indexBackup" */))
const _081a8e70 = () => interopDefault(import('../pages/admin/operators/inbox.vue' /* webpackChunkName: "pages/admin/operators/inbox" */))
const _981c6246 = () => interopDefault(import('../pages/admin/payments/configuration/index.vue' /* webpackChunkName: "pages/admin/payments/configuration/index" */))
const _781ded44 = () => interopDefault(import('../pages/admin/preventiveDelivery/configuration/index.vue' /* webpackChunkName: "pages/admin/preventiveDelivery/configuration/index" */))
const _5ac3f51c = () => interopDefault(import('../pages/admin/users/configuration/index.vue' /* webpackChunkName: "pages/admin/users/configuration/index" */))
const _8b9c1358 = () => interopDefault(import('../pages/citizen/certificates/verification/index.vue' /* webpackChunkName: "pages/citizen/certificates/verification/index" */))
const _52a1c480 = () => interopDefault(import('../pages/admin/inspectores/configuration/catalog.vue' /* webpackChunkName: "pages/admin/inspectores/configuration/catalog" */))
const _c9a04aea = () => interopDefault(import('../pages/admin/preventiveDelivery/configuration/catalog.vue' /* webpackChunkName: "pages/admin/preventiveDelivery/configuration/catalog" */))
const _bb7e76ae = () => interopDefault(import('../pages/citizen/certificates/form-certs/back_cert.vue' /* webpackChunkName: "pages/citizen/certificates/form-certs/back_cert" */))
const _5a8ce7fb = () => interopDefault(import('../pages/admin/citas/configuration/office/new.vue' /* webpackChunkName: "pages/admin/citas/configuration/office/new" */))
const _2befdb52 = () => interopDefault(import('../themes/Andromeda/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6bbe8f71 = () => interopDefault(import('../pages/admin/citas/configuration/office/_officeId.vue' /* webpackChunkName: "pages/admin/citas/configuration/office/_officeId" */))
const _62afa4ba = () => interopDefault(import('../pages/admin/catalogs/terms/_id.vue' /* webpackChunkName: "pages/admin/catalogs/terms/_id" */))
const _0ef93820 = () => interopDefault(import('../pages/admin/citas/details/_detailsId.vue' /* webpackChunkName: "pages/admin/citas/details/_detailsId" */))
const _2e08b516 = () => interopDefault(import('../pages/admin/forms/answers/_form/index.vue' /* webpackChunkName: "pages/admin/forms/answers/_form/index" */))
const _16541891 = () => interopDefault(import('../pages/admin/forms/builder/_builderid.vue' /* webpackChunkName: "pages/admin/forms/builder/_builderid" */))
const _c302871e = () => interopDefault(import('../pages/admin/forms/details/_detailsid.vue' /* webpackChunkName: "pages/admin/forms/details/_detailsid" */))
const _00d1d180 = () => interopDefault(import('../pages/admin/inspectores/inspectionDetailsId/_inspectionDetailsId.vue' /* webpackChunkName: "pages/admin/inspectores/inspectionDetailsId/_inspectionDetailsId" */))
const _1ec27151 = () => interopDefault(import('../pages/admin/operators/details/_detailsid.vue' /* webpackChunkName: "pages/admin/operators/details/_detailsid" */))
const _05e2421a = () => interopDefault(import('../pages/admin/preventiveDelivery/preventiveDeliveryId/_preventiveDeliveryId.vue' /* webpackChunkName: "pages/admin/preventiveDelivery/preventiveDeliveryId/_preventiveDeliveryId" */))
const _bd6b1486 = () => interopDefault(import('../pages/citizen/certificates/digital-identity/_cert.vue' /* webpackChunkName: "pages/citizen/certificates/digital-identity/_cert" */))
const _7da34830 = () => interopDefault(import('../pages/citizen/certificates/form-certs/_cert.vue' /* webpackChunkName: "pages/citizen/certificates/form-certs/_cert" */))
const _0fbb33c8 = () => interopDefault(import('../pages/admin/forms/answers/_form/_answerId.vue' /* webpackChunkName: "pages/admin/forms/answers/_form/_answerId" */))
const _72204208 = () => interopDefault(import('../pages/admin/catalogs/terms/_.vue' /* webpackChunkName: "pages/admin/catalogs/terms/_" */))
const _03a974eb = () => interopDefault(import('../pages/admin/catalogs/_id.vue' /* webpackChunkName: "pages/admin/catalogs/_id" */))
const _677afb70 = () => interopDefault(import('../pages/admin/forms/_formid.vue' /* webpackChunkName: "pages/admin/forms/_formid" */))
const _6a2331aa = () => interopDefault(import('../pages/admin/modules/_moduleId/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/index" */))
const _52e64ecc = () => interopDefault(import('../pages/admin/modules/_moduleId/playlist/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/playlist/index" */))
const _150168b4 = () => interopDefault(import('../pages/admin/modules/_moduleId/playlist/_playlistId.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/playlist/_playlistId" */))
const _fb4d19bc = () => interopDefault(import('../pages/admin/modules/_moduleId/_submoduleId/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/_submoduleId/index" */))
const _299d72d6 = () => interopDefault(import('../pages/admin/modules/_moduleId/_submoduleId/inbox.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/_submoduleId/inbox" */))
const _a6abb836 = () => interopDefault(import('../pages/admin/modules/_moduleId/_submoduleId/uploads/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/_submoduleId/uploads/index" */))
const _f31f3aaa = () => interopDefault(import('../pages/admin/modules/_moduleId/_submoduleId/details/_detailsid.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/_submoduleId/details/_detailsid" */))
const _69668426 = () => interopDefault(import('../pages/certificates/_id.vue' /* webpackChunkName: "pages/certificates/_id" */))
const _4c783554 = () => interopDefault(import('../pages/finalDocument/_finalDocument.vue' /* webpackChunkName: "pages/finalDocument/_finalDocument" */))
const _511e1e4e = () => interopDefault(import('../pages/payments/_paymentId.vue' /* webpackChunkName: "pages/payments/_paymentId" */))
const _73ceff03 = () => interopDefault(import('../pages/playlist/_id.vue' /* webpackChunkName: "pages/playlist/_id" */))
const _fd21d0f2 = () => interopDefault(import('../themes/Andromeda/pages/modules/_module/_submodule/index.vue' /* webpackChunkName: "pages/modules/_module/_submodule/index" */))
const _6048be6f = () => interopDefault(import('../themes/Andromeda/pages/modules/_module/_submodule/_postId.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId" */))
const _378a7ead = () => interopDefault(import('../pages/modules/_module/_submodule/_postId/index.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/index" */))
const _1c5104ba = () => interopDefault(import('../pages/modules/_module/_submodule/_postId/appointment.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/appointment" */))
const _938892d2 = () => interopDefault(import('../pages/modules/_module/_submodule/_postId/description.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/description" */))
const _9b59c5ac = () => interopDefault(import('../pages/modules/_module/_submodule/_postId/request.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/request" */))
const _8e3a12fc = () => interopDefault(import('../pages/modules/_module/_submodule/_postId/steps.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/steps" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/autenticar-error",
    component: _6a275ef7,
    name: "autenticar-error"
  }, {
    path: "/auth",
    component: _6b643131,
    name: "auth",
    children: [{
      path: "ChangePasswordFuncionarios",
      component: _7890c21e,
      name: "auth-index-ChangePasswordFuncionarios"
    }, {
      path: "ChangePasswordInstitucionales",
      component: _8eca0ece,
      name: "auth-index-ChangePasswordInstitucionales"
    }, {
      path: "LinkWallet",
      component: _6fc3a381,
      name: "auth-index-LinkWallet"
    }, {
      path: "Login",
      component: _5299dc2a,
      name: "auth-index-Login"
    }, {
      path: "login-funcionarios",
      component: _bd87e5a6,
      name: "auth-index-login-funcionarios"
    }, {
      path: "login-institucional",
      component: _6c0796d6,
      name: "auth-index-login-institucional"
    }, {
      path: "LoginWallet",
      component: _12149344,
      name: "auth-index-LoginWallet"
    }, {
      path: "Logout",
      component: _4cc033f8,
      name: "auth-index-Logout"
    }, {
      path: "Register",
      component: _e23989de,
      name: "auth-index-Register"
    }, {
      path: "ResetPassword",
      component: _77b0dd0c,
      name: "auth-index-ResetPassword"
    }]
  }, {
    path: "/digital-citizen",
    component: _7d22cc1d,
    name: "digital-citizen"
  }, {
    path: "/digital-citizen-levels",
    component: _2c57bb4f,
    name: "digital-citizen-levels"
  }, {
    path: "/digital-services",
    component: _60067b81,
    name: "digital-services"
  }, {
    path: "/email-action-handler",
    component: _6fbe968a,
    name: "email-action-handler"
  }, {
    path: "/expediente",
    component: _12764f66,
    name: "expediente",
    children: [{
      path: "apoderados",
      component: _26c55b2c,
      name: "expediente-apoderados"
    }, {
      path: "appointments",
      component: _29041de9,
      name: "expediente-appointments"
    }, {
      path: "companies",
      component: _62f70ace,
      name: "expediente-companies"
    }, {
      path: "identidad",
      component: _6ac47fac,
      name: "expediente-identidad"
    }, {
      path: "inspections",
      component: _67f23b80,
      name: "expediente-inspections"
    }, {
      path: "payments",
      component: _7cecae22,
      name: "expediente-payments"
    }, {
      path: "portadocumentos",
      component: _31cb2306,
      name: "expediente-portadocumentos"
    }, {
      path: "appointments/:appointmentId",
      component: _feda08d8,
      name: "expediente-appointments-appointmentId"
    }, {
      path: "inspections/:inspectionId",
      component: _5690d23c,
      name: "expediente-inspections-inspectionId"
    }, {
      path: "module/:module_id?/:submodule_id",
      component: _4651c3b1,
      name: "expediente-module-module_id-submodule_id"
    }, {
      path: "module/:module_id?/:submodule_id?/:details_id",
      component: _51e3d4f6,
      name: "expediente-module-module_id-submodule_id-details_id"
    }]
  }, {
    path: "/faq",
    component: _6b59a400,
    name: "faq"
  }, {
    path: "/forbidden",
    component: _1b80743a,
    name: "forbidden"
  }, {
    path: "/forward-email",
    component: _c59b9404,
    name: "forward-email"
  }, {
    path: "/mercadopago",
    component: _2a138174,
    name: "mercadopago"
  }, {
    path: "/motordepagos",
    component: _38463aec,
    name: "motordepagos"
  }, {
    path: "/notice-of-privacy",
    component: _5f559c91,
    name: "notice-of-privacy"
  }, {
    path: "/persona-moral",
    component: _fc2c8b78,
    name: "persona-moral"
  }, {
    path: "/predial",
    component: _4ca8cdfd,
    name: "predial"
  }, {
    path: "/reset-password",
    component: _68650242,
    name: "reset-password"
  }, {
    path: "/server-error",
    component: _ec5654f8,
    name: "server-error"
  }, {
    path: "/terms-and-conditions",
    component: _71cb36fa,
    name: "terms-and-conditions"
  }, {
    path: "/terms-of-service",
    component: _0c02766a,
    name: "terms-of-service"
  }, {
    path: "/validacion-pago",
    component: _fefc4974,
    name: "validacion-pago"
  }, {
    path: "/verificador",
    component: _29824366,
    name: "verificador"
  }, {
    path: "/what-is",
    component: _adc04386,
    name: "what-is"
  }, {
    path: "/admin/catalogs",
    component: _21ee447a,
    name: "admin-catalogs"
  }, {
    path: "/admin/citas",
    component: _fd67f8a6,
    name: "admin-citas"
  }, {
    path: "/admin/dashboard",
    component: _68f58a7e,
    name: "admin-dashboard"
  }, {
    path: "/admin/filters",
    component: _8f860830,
    name: "admin-filters"
  }, {
    path: "/admin/inspectores",
    component: _9f536f5c,
    name: "admin-inspectores"
  }, {
    path: "/admin/modules",
    component: _6adf8074,
    name: "admin-modules"
  }, {
    path: "/admin/payments",
    component: _5df4f494,
    name: "admin-payments"
  }, {
    path: "/admin/preventiveDelivery",
    component: _6e2fd45d,
    name: "admin-preventiveDelivery"
  }, {
    path: "/admin/profile",
    component: _305bbfd4,
    name: "admin-profile"
  }, {
    path: "/admin/settings",
    component: _dc0a11a8,
    name: "admin-settings"
  }, {
    path: "/auth/CompleteData",
    component: _a60f3dd8,
    name: "auth-CompleteData"
  }, {
    path: "/citizen/certificates",
    component: _0b3e72cc,
    name: "citizen-certificates"
  }, {
    path: "/tools/ide",
    component: _700c1a63,
    name: "tools-ide"
  }, {
    path: "/admin/catalogs/new",
    component: _175f49d1,
    name: "admin-catalogs-new"
  }, {
    path: "/admin/citas/configuration",
    component: _474d1094,
    name: "admin-citas-configuration"
  }, {
    path: "/admin/forms/builder",
    component: _57b88c28,
    name: "admin-forms-builder"
  }, {
    path: "/admin/inspectores/configuration",
    component: _e1085f0e,
    name: "admin-inspectores-configuration"
  }, {
    path: "/admin/modules/configuration",
    component: _d6b1b5ca,
    name: "admin-modules-configuration"
  }, {
    path: "/admin/modules/indexBackup",
    component: _472a4c56,
    name: "admin-modules-indexBackup"
  }, {
    path: "/admin/operators/inbox",
    component: _081a8e70,
    name: "admin-operators-inbox"
  }, {
    path: "/admin/payments/configuration",
    component: _981c6246,
    name: "admin-payments-configuration"
  }, {
    path: "/admin/preventiveDelivery/configuration",
    component: _781ded44,
    name: "admin-preventiveDelivery-configuration"
  }, {
    path: "/admin/users/configuration",
    component: _5ac3f51c,
    name: "admin-users-configuration"
  }, {
    path: "/citizen/certificates/verification",
    component: _8b9c1358,
    name: "citizen-certificates-verification"
  }, {
    path: "/admin/inspectores/configuration/catalog",
    component: _52a1c480,
    name: "admin-inspectores-configuration-catalog"
  }, {
    path: "/admin/preventiveDelivery/configuration/catalog",
    component: _c9a04aea,
    name: "admin-preventiveDelivery-configuration-catalog"
  }, {
    path: "/citizen/certificates/form-certs/back_cert",
    component: _bb7e76ae,
    name: "citizen-certificates-form-certs-back_cert"
  }, {
    path: "/admin/citas/configuration/office/new",
    component: _5a8ce7fb,
    name: "admin-citas-configuration-office-new"
  }, {
    path: "/",
    component: _2befdb52,
    name: "index"
  }, {
    path: "/admin/citas/configuration/office/:officeId?",
    component: _6bbe8f71,
    name: "admin-citas-configuration-office-officeId"
  }, {
    path: "/admin/catalogs/terms/:id?",
    component: _62afa4ba,
    name: "admin-catalogs-terms-id"
  }, {
    path: "/admin/citas/details/:detailsId?",
    component: _0ef93820,
    name: "admin-citas-details-detailsId"
  }, {
    path: "/admin/forms/answers/:form",
    component: _2e08b516,
    name: "admin-forms-answers-form"
  }, {
    path: "/admin/forms/builder/:builderid",
    component: _16541891,
    name: "admin-forms-builder-builderid"
  }, {
    path: "/admin/forms/details/:detailsid?",
    component: _c302871e,
    name: "admin-forms-details-detailsid"
  }, {
    path: "/admin/inspectores/inspectionDetailsId/:inspectionDetailsId?",
    component: _00d1d180,
    name: "admin-inspectores-inspectionDetailsId-inspectionDetailsId"
  }, {
    path: "/admin/operators/details/:detailsid?",
    component: _1ec27151,
    name: "admin-operators-details-detailsid"
  }, {
    path: "/admin/preventiveDelivery/preventiveDeliveryId/:preventiveDeliveryId?",
    component: _05e2421a,
    name: "admin-preventiveDelivery-preventiveDeliveryId-preventiveDeliveryId"
  }, {
    path: "/citizen/certificates/digital-identity/:cert?",
    component: _bd6b1486,
    name: "citizen-certificates-digital-identity-cert"
  }, {
    path: "/citizen/certificates/form-certs/:cert?",
    component: _7da34830,
    name: "citizen-certificates-form-certs-cert"
  }, {
    path: "/admin/forms/answers/:form?/:answerId",
    component: _0fbb33c8,
    name: "admin-forms-answers-form-answerId"
  }, {
    path: "/admin/catalogs/terms/*",
    component: _72204208,
    name: "admin-catalogs-terms-all"
  }, {
    path: "/admin/catalogs/:id",
    component: _03a974eb,
    name: "admin-catalogs-id"
  }, {
    path: "/admin/forms/:formid?",
    component: _677afb70,
    name: "admin-forms-formid"
  }, {
    path: "/admin/modules/:moduleId",
    component: _6a2331aa,
    name: "admin-modules-moduleId"
  }, {
    path: "/admin/modules/:moduleId/playlist",
    component: _52e64ecc,
    name: "admin-modules-moduleId-playlist"
  }, {
    path: "/admin/modules/:moduleId/playlist/:playlistId",
    component: _150168b4,
    name: "admin-modules-moduleId-playlist-playlistId"
  }, {
    path: "/admin/modules/:moduleId/:submoduleId",
    component: _fb4d19bc,
    name: "admin-modules-moduleId-submoduleId"
  }, {
    path: "/admin/modules/:moduleId/:submoduleId/inbox",
    component: _299d72d6,
    name: "admin-modules-moduleId-submoduleId-inbox"
  }, {
    path: "/admin/modules/:moduleId/:submoduleId/uploads",
    component: _a6abb836,
    name: "admin-modules-moduleId-submoduleId-uploads"
  }, {
    path: "/admin/modules/:moduleId/:submoduleId/details/:detailsid?",
    component: _f31f3aaa,
    name: "admin-modules-moduleId-submoduleId-details-detailsid"
  }, {
    path: "/certificates/:id?",
    component: _69668426,
    name: "certificates-id"
  }, {
    path: "/finalDocument/:finalDocument?",
    component: _4c783554,
    name: "finalDocument-finalDocument"
  }, {
    path: "/payments/:paymentId?",
    component: _511e1e4e,
    name: "payments-paymentId"
  }, {
    path: "/playlist/:id?",
    component: _73ceff03,
    name: "playlist-id"
  }, {
    path: "/modules/:module?/:submodule",
    component: _fd21d0f2,
    name: "modules-module-submodule"
  }, {
    path: "/modules/:module?/:submodule?/:postId",
    component: _6048be6f,
    children: [{
      path: "",
      component: _378a7ead,
      name: "modules-module-submodule-postId"
    }, {
      path: "appointment",
      component: _1c5104ba,
      name: "modules-module-submodule-postId-appointment"
    }, {
      path: "description",
      component: _938892d2,
      name: "modules-module-submodule-postId-description"
    }, {
      path: "request",
      component: _9b59c5ac,
      name: "modules-module-submodule-postId-request"
    }, {
      path: "steps",
      component: _8e3a12fc,
      name: "modules-module-submodule-postId-steps"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
